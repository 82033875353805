<template>
    <v-menu
      v-model="filterMenu" 
      offset-y 
      :nudge-bottom="5" 
      :close-on-content-click="false">
      <template #activator="{ on }">
        <v-btn
          :disabled="disabled"
          rounded 
          v-on="on" 
          text
          class="ml-auto text-capitalize"
          color="main">
          <v-icon left>mdi-filter-outline</v-icon>
          Filters
          <v-badge
            class="ml-2 mb-2"
            color="accent"
            :content="filterCount"
            :value="filterCount > 0" />
        </v-btn>
      </template>
      <v-card width="350" class="background">
        <v-card-text>
          <v-row dense v-for="(field, idx) in searchFields" :key="idx" justify="center">
            <v-col class="ma-0 pa-0" :cols="field.type === 'boolean' ? 6 : null">
              <v-text-field
                v-if="field.type === 'text'"
                style="padding: 5px"
                v-model="filters[`${field.key}`]"
                :label="field.name"
                outlined
                hide-details="auto"
                hint="Press enter to search"
                clearable
                :loading="Boolean(filters[field.key]) && loading"
                color="primary"
                dense
                prepend-inner-icon="mdi-magnify"
                @click:clear="clearFilter(field.key)"
                @keydown.enter="updateSearchFilters"
                background-color="white">
              </v-text-field>
              <v-autocomplete
                v-else-if="field.type === 'autocomplete'"
                style="padding: 5px"
                v-model="filters[`${field.key}`]"
                :label="field.name"
                outlined
                hide-details
                clearable
                :items="field.options"
                item-text="name"
                item-value="id"
                background-color="white"
                dense
                prepend-inner-icon="mdi-filter-outline"
                @change="updateSearchFilters">
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </template>
  <script>
  export default {
    data() {
      return {
        ordersOnly: false,
        searchFields: [
          { name: 'Status', key: 'upload_status', type: 'autocomplete', options: 
            [
              { name: 'Completed', id: 'COMPLETED'},
              { name: 'Running', id: 'RUNNING'},
              { name: 'Pending', id: 'PENDING'},
              { name: 'Completed with Warnings', id: 'COMPLETED_WITH_WARNINGS'},
              { name: 'Cancelled', id: 'CANCELLED'},
              { name: 'Failed', id: 'FAILED'},
              { name: 'Exception', id: 'EXCEPTION' }
              
            ] },
          { name: 'File Name', key: 'file_name', type: 'text' }
        ],
        filters: {},
        filterMenu: false
      }
    },
    name: 'ContractFilters',
    props: {
      value: Object,
      loading: Boolean,
      disabled: Boolean
    },
    watch: {
      value: {
        handler(newValue) {
          if (newValue) {
            this.filters = { ...newValue }
          }
        },
        deep: true
      }
    },
    created() {
      if (this.value) {
        this.filters = { ...this.value }
      }
    },
    computed: {
      activeFilters () {
        return Object.keys(this.filters).filter(key => {
          return Boolean(this.filters[key])
        })
      },
      filtersObj () {
        if (this.activeFilters.length > 0) {
          return this.activeFilters.reduce((obj, key) => {
            obj[key] = this.filters[key]
            return obj
          }, {})
        }
        return {}
      },
      filterCount() {
        return Object.keys(this.value).length || 0
      }
    },
    methods: {
      clearFilter(key) {
        this.filters[key] = null
        this.filterMenu = false
        this.updateSearchFilters()
      },
      updateSearchFilters () {
        this.$emit('contractFiltersInput', this.filtersObj)
        this.filterMenu = false
      }
    }
  }
  </script>